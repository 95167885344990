import React from 'react'
import styled from 'styled-components'

import PolicyAbbr from '../PolicyAbbr'
import InfoBubble from '../../InfoBubble/InfoBubble'

import Table from '../VacuumTable/Table'
import Thead from '../VacuumTable/Thead'

import AngledHeader from '../VacuumTable/headers/AngledHeader'
import Header from '../VacuumTable/headers/Header'

import FlagLink from '../VacuumTable/columns/FlagLink'
import AdoptionStatus from '../VacuumTable/columns/AdoptionStatus'
import BlueColumn from '../VacuumTable/columns/BlueColumn'

import sortNumeric from '../VacuumTable/sorting/sortNumeric'
import sortString from '../VacuumTable/sorting/sortString'

const StyledTable = styled(Table)`
  padding-top: 0;
  & td {
    &:nth-of-type(1) {
      width: 20%;
      padding-right: 0;
    }
    &:nth-of-type(2) {
      width: 20%;
      padding: 0 5%;
    }
    &:nth-of-type(3) {
      width: 15%;
      padding: 0 2%;
    }
    &:nth-of-type(4) {
      width: 15%;
      padding: 0 2%;
    }
  }
`

const DataNotAvailable = styled.span`
  font-style: italic;
  color: ${({ theme }) => theme.colorMiddleGray};
`

const AdoptionTable = ({ setData, tableData, ...props }) => {
  const wideCols = true

  return (
    <StyledTable>
      <Thead>
        <tr>
          <Header
            sortFunc={sortString({ field: 'place_name', stateSetter: setData })}
            name="country"
          >
            Country
          </Header>
          <AngledHeader
            name="adoption"
            sortFunc={sortNumeric({ field: 'adoption', stateSetter: setData })}
            angle={wideCols ? 0 : 60}
          >
            <PolicyAbbr>{props.pageContext.abbr}</PolicyAbbr>
            <span>{props.pageContext.name}</span>
          </AngledHeader>
          <Header
            name="incidence"
            sortFunc={sortNumeric({ field: 'incidence', stateSetter: setData })}
          >
            New HIV Infections
            <InfoBubble>
              This incidence includes new HIV infections, for individuals of all
              ages, in 2021.
              <br />
              <br />
              Incidence data are provided by UNAIDS based on data reported in
              the Global AIDS Update 2023 (covering through 2022).{' '}
              <a href="https://www.unaids.org/en/resources/documents/2023/global-aids-update-2023">
                Full report and methodology available here.
              </a>
            </InfoBubble>
          </Header>
          <Header
            name="art"
            sortFunc={sortNumeric({ field: 'art', stateSetter: setData })}
          >
            ART Treatment
            <InfoBubble>
              The ART treatment percentage represents the proportion of people
              who are receiving ART treatment among all those living with HIV.
              <br />
              <br />
              ART treatment data are provided by UNAIDS based on data reported
              in the Global AIDS Update 2023 (covering through 2022).{' '}
              <a href="https://www.unaids.org/en/resources/documents/2023/global-aids-update-2023">
                Full report and methodology available here.
              </a>
            </InfoBubble>
          </Header>
        </tr>
      </Thead>
      <tbody>
        {tableData &&
          tableData.map(row => (
            <tr key={row.place_id}>
              <FlagLink iso2={row.place_id}>{row.place_name}</FlagLink>
              <AdoptionStatus label={wideCols} status={row.adoption} />
              <BlueColumn>
                {row.incidence ? (
                  `${row.incidence} cases`
                ) : (
                  <DataNotAvailable>data not available</DataNotAvailable>
                )}
              </BlueColumn>
              <BlueColumn>
                {row.art ? (
                  `${row.art}%`
                ) : (
                  <DataNotAvailable>data not available</DataNotAvailable>
                )}
              </BlueColumn>
            </tr>
          ))}
      </tbody>
    </StyledTable>
  )
}

export default AdoptionTable
