// line styles, where the key is the
// policy value returned by the API.
// null is included and commented
// out for testing.

const lineStyles = {
  // null: {
  //   fill: 'none',
  //   stroke: 'red',
  //   strokeWidth: 1,
  //   strokeDasharray: '3 3',
  // },
  reference: {
    fill: 'none',
    stroke: 'red',
    strokeWidth: 1,
    strokeDasharray: '1 1',
  },
  0: {
    fill: 'rgba( 247, 140, 63, .7)',
    stroke: 'rgba( 247, 140, 63, 1)',
    strokeWidth: 2,
  },
  0.5: {
    // fill: 'none',
    // stroke: 'darkgrey',
    // fill: 'rgba( 247, 180, 63, .47)',
    fill: 'rgb(250 229 149 / 75%)',
    stroke: 'rgb(250 229 149 / 100%)',
    // fill: 'none',
    // stroke: 'rgba( 247, 180, 63, 1)',
    strokeWidth: 2,
    // strokeDasharray: '3 3',
  },
  1: {
    fill: 'rgba( 53, 121, 161, .7)',
    stroke: 'rgba( 53, 121, 161, 1)',
    strokeWidth: 2,
  },
}

export default lineStyles
