import React from 'react'
import styled from 'styled-components'

const strokeWidth = 0.5

const Path = styled.path`
  stroke: ${props => props.theme.colorMiddleGray};
  fill: none;
  stroke-width: ${strokeWidth};
  stroke-dasharray: 2 2;
  transition: 250ms ease;
`

const ConnectingLines = ({ startYear, stacks, index, dim }) => {
  const endYear = stacks[Number(startYear) + 1]
  // if there is no next year, short-circuit
  if (!endYear) return <></>

  const startX = dim.axes.x.scale(index) + dim.barWidth / 2
  const endX = dim.axes.x.scale(index + 1) - dim.barWidth / 2

  const yPairs = Object.entries(stacks[startYear]).map(([key, bar]) => {
    if (endYear[key])
      return {
        key: startYear + key,
        y1: bar.end,
        y2: endYear[key].end,
      }
    // skip lines that don't
    // exist in both years
    else return {}
  })

  return yPairs.map(line => (
    <Path
      key={line.key}
      d={`
        M ${startX},${dim.axes.y.scale(line.y1) + strokeWidth / 2}
        L ${endX},${dim.axes.y.scale(line.y2) + strokeWidth / 2}
      `}
    />
  ))
}

export default ConnectingLines
