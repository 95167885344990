import * as d3 from 'd3/dist/d3.min'

const binCountries = ({ incidence, adoption, nBins }) => {
  const t0 = performance.now()
  // create countries with merged incidence and adoption
  // create list of unique adoption levels
  const countries = {}
  const adoptionLevels = new Set()

  const fifthPercentile = -50.18
  const ninetyFifthPercentile = 41.12

  let max = null
  let min = null

  Object.entries(incidence).forEach(([key, country]) => {
    if (
      country.incidence >= fifthPercentile &&
      country.incidence <= ninetyFifthPercentile
    ) {
      countries[key] = { ...country, ...adoption[key] }
      adoptionLevels.add(adoption[key].adoption)
      max = country.incidence > max ? country.incidence : max
      min = country.incidence < min ? country.incidence : min
    }
  })

  // console.log(adoptionLevels)

  // const scale = d3.scaleSymlog().domain([min, max]).range([0, 100])

  // calculate bin width
  // const binWidth = (Math.log(max) - Math.log(min)) / nBins
  const binWidth = (max - min) / nBins
  // const binWidth = 100 / nBins
  // console.log(binWidth)

  // create an object with an object for each adoption level
  // inside each adoption level, create a bin with 0 countries
  const bins = {}
  adoptionLevels.forEach(level => {
    bins[level] = {}
    // const start = Math.floor(Math.log(min + min) / binWidth)
    // const stop = Math.floor(Math.log(max + min) / binWidth)
    const start = Math.floor(min / binWidth)
    const stop = Math.floor(max / binWidth)
    // const start = Math.floor(0 / binWidth)
    // const stop = Math.floor(100 / binWidth)

    let bin = start
    while (bin <= stop) {
      bins[level][bin] = 0
      bin++
    }
  })

  let maxCount = 0
  Object.values(countries).forEach(country => {
    // calculate which bin the country goes in
    let bin = Math.floor(country.incidence / binWidth)
    // let bin = Math.floor(Math.log(country.incidence + min + 1) / binWidth)
    // console.log(bin)
    // let bin = Math.floor(scale(country.incidence) / binWidth)
    // increment the appropriate bin
    bins[country.adoption][bin]++

    // find max count for overall histogram
    maxCount =
      country.adoption !== null && bins[country.adoption][bin] > maxCount
        ? bins[country.adoption][bin]
        : maxCount
  })

  // create arrays of sorted points: [{x: v, y: v}, ...]
  const lines = {}
  Object.entries(bins).forEach(([binKey, bin]) => {
    lines[binKey] = Object.keys(bin)
      .sort((a, b) => a - b)
      .map(x => ({
        x: Math.floor(Number(x) * binWidth),
        y: bin[Number(x)],
      }))
  })

  const t1 = performance.now()
  // console.log('Computing histogram bins took ' + (t1 - t0) + ' milliseconds.')
  // console.log(lines)
  return { lines, maxCount, binWidth, nBins }
}

export default binCountries
