import React from 'react'
import styled, { useTheme } from 'styled-components'
import SVGMap from '../SVGMap/SVGMap'

import PolicyAbbr from '../PolicyAbbr'
import { Link } from 'gatsby'

const HeaderBar = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'text     map'
    'controls map';
  @media only screen and (max-width: 850px) {
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas:
      'text'
      'map'
      'controls';
  }
`
const TextHolder = styled.div`
  grid-area: text;
`
const MapLink = styled(Link)`
  grid-area: map;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #fff;
  transition: 250ms ease;
  margin-top: -10px;
  padding-top: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(150, 191, 221, 0);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0);
  color: ${props => props.theme.colorBlack};
  &:hover {
    background: rgba(150, 191, 221, 0.124);
    border-color: rgba(150, 191, 221, 0.24);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.025);
    text-decoration: none;
  }
  @media only screen and (max-width: 850px) {
    margin-top: 10px;
  }
`
const ControlsHolder = styled.div`
  grid-area: controls;
`

const RegionalAdoptionHeader = props => {
  const theme = useTheme()

  return (
    <HeaderBar>
      <TextHolder>
        <h4>
          <PolicyAbbr>{props.pageContext.abbr}</PolicyAbbr>
          {props.pageContext.name}
        </h4>
        <h2>Adoption status by region</h2>
        <p>
          Compare regional {props.pageContext.abbr} adoption status for the most
          current data
        </p>
      </TextHolder>
      <MapLink to="/map">
        <span>Click to view {props.pageContext.abbr} on map</span>
        <SVGMap
          data={props.pageContext.observations.mostRecent}
          colors={{
            null: 'url(#nullPattern)',
            0: theme.colorVeryFew,
            0.5: theme.colorSome,
            1: theme.colorMost,
          }}
        />
      </MapLink>
      <ControlsHolder>{props.children}</ControlsHolder>
    </HeaderBar>
  )
}

export default RegionalAdoptionHeader
