import React from 'react'
import styled from 'styled-components'

import * as d3 from 'd3/dist/d3.min'

import binCountries from './binCountries'
import getIncidenceData from './getIncidenceData'

import lineStyles from './lineStyles'
import Axes from './Axes'
import Legend from './Legend'

const nBins = 18

const AnimatedPath = styled.path`
  transition: 300ms ease;
`
const Svg = styled.svg`
  margin-top: 25px;
`
const Mask = styled.rect`
  fill: white;
`

const DistributionPlot = props => {
  const partialAllowed = Object.keys(props.pageContext.values).includes(
    'Partially adopted'
  )

  const [selectedCurves, setSelectedCurves] = React.useState({
    0: true,
    0.5: true,
    1: true,
  })

  const flatLine = [...Array(nBins + 1)].map((_, i) => ({ x: i, y: 0 }))
  const [bins, setBins] = React.useState({
    maxCount: 30,
    lines: {
      null: flatLine,
      0: flatLine,
      0.5: flatLine,
      1: flatLine,
    },
  })

  const dim = {
    width: 550,
    height: 170,
    padding: {
      left: 50,
      // right: 15,
      // right: 35,
      right: 100,
      bottom: 45,
      top: 10,
    },
  }

  dim.axes = {
    x: {
      start: dim.padding.left,
      end: dim.width - dim.padding.right,
      length: dim.width - dim.padding.right - dim.padding.left,
    },
    y: {
      start: dim.height - dim.padding.bottom,
      end: dim.padding.top,
      length: dim.height - dim.padding.top - dim.padding.bottom,
    },
  }

  dim.axes.x.scale = d3
    .scaleLinear()
    // .scaleSymlog()
    // the +2 accounts for the (0, 0) and
    // (xMax, 0) points added at the ends
    .domain([0, nBins + 2])
    .range([dim.axes.x.start, dim.axes.x.end])

  dim.axes.y.scale =
    bins &&
    d3
      .scaleLinear()
      .domain([0, bins.maxCount])
      .range([dim.axes.y.start, dim.axes.y.end])

  const pathGen = d3
    .line()
    .curve(d3['curveBasis'])
    // .curve(d3['curveCardinal'])
    // .curve(d3['curveCatmullRom'])
    // .curve(d3['curveStep'])
    .x((_, i) => dim.axes.x.scale(i))
    .y(d => dim.axes.y.scale(d.y))

  const pathGen2 = d3
    .line()
    // .curve(d3['curveBasis'])
    // .curve(d3['curveCatmullRom'])
    .curve(d3['curveStep'])
    .x((_, i) => dim.axes.x.scale(i))
    .y(d => dim.axes.y.scale(d.y))

  React.useEffect(() => {
    const getBins = async () => {
      setBins(
        binCountries({
          // incidence data should be moved into pageContext
          incidence: await getIncidenceData(props.pageContext),
          adoption: props.pageContext.observations.mostRecent,
          nBins,
        })
      )
    }
    getBins()
  }, [props.pageContext])

  const sortedKeys = Object.entries(props.adoption)
    .sort((a, b) => b[1] - a[1])
    .map(([k]) => k)

  return (
    <Svg viewBox={`0 0 ${dim.width} ${dim.height}`}>
      {sortedKeys.map(
        key =>
          lineStyles[key] && (
            // <React.Fragment key={key}>
            <AnimatedPath
              key={key}
              style={lineStyles[key]}
              d={pathGen([
                { x: 0, y: 0 },
                ...(selectedCurves[key] ? bins.lines[key] : flatLine),
                { x: 0, y: 0 },
              ])}
            />
            // {/* <AnimatedPath
            //   key={key}
            //   style={lineStyles.reference}
            //   d={pathGen2([{ x: 0, y: 0 }, ...line, { x: 0, y: 0 }])}
            // /> */}
            // </React.Fragment>
          )
      )}
      <Mask
        x={dim.axes.x.start}
        width={dim.axes.x.length}
        y={dim.axes.y.start}
        height={10}
      />
      <Axes x={dim.axes.x} y={dim.axes.y} bins={bins} />
      <Legend {...{ partialAllowed, selectedCurves, setSelectedCurves }} />
    </Svg>
  )
}

export default DistributionPlot
