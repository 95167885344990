import React from 'react'
import styles from './DetailTable.module.scss'

const CodebookSection = props => (
  <table className={styles.detailTable}>
    <tbody>
      <tr>
        <td style={{ flexBasis: props.colWidth }}>
          <strong>Question</strong>
        </td>
        <td>{props.indicator.question}</td>
      </tr>
      <tr className={styles.divider} />
      <tr>
        <td style={{ flexBasis: props.colWidth }}>
          <strong>Possible values</strong>
        </td>
        <td className={styles.hiddenSm}>
          <strong>Definition of values</strong>
        </td>
      </tr>
      {Object.entries(props.indicator.values).map(([value, definition]) => (
        <tr key={value}>
          <td style={{ flexBasis: props.colWidth }}>{value}</td>
          <td>{definition}</td>
        </tr>
      ))}
      {props.indicator.source && (
        <>
          <tr className={styles.divider} />
          <tr>
            <td style={{ flexBasis: props.colWidth }}>
              <strong>Policy recommendation</strong>
            </td>
            <td>{props.indicator.source}</td>
          </tr>
        </>
      )}
    </tbody>
  </table>
)

export default CodebookSection
