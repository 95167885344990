import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import axios from 'axios'

import AdoptionTable from './AdoptionTable'
import Paginator from '../VacuumTable/Paginator'
import FilterFrame from '../../CompareFilters/FilterFrame'
import CountryFilters from '../../CompareFilters/countries/CountryFilters'

const API_URL = process.env.GATSBY_METRICS_API_URL

const TableHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin-bottom: 10rem;
`

const AdoptionTableSection = props => {
  const [data, setData] = useState()

  const incidenceMetricID = props.pageContext.epiMetrics.find(
    m => m.name === 'HIV Incidence'
  ).value

  const ARTMetricID = props.pageContext.epiMetrics.find(
    m => m.name === 'HIV patients on ART'
  ).value

  useEffect(() => {
    const getEpiMetrics = async () => {
      const today = new Date().toISOString().split('T')[0]

      const incidence = await axios(`${API_URL}/observations`, {
        params: {
          temporal_resolution: 'yearly',
          spatial_resolution: 'country',
          metric_id: incidenceMetricID,
          lag_allowed: 1000,
          start: today,
          end: today,
        },
      })

      const art = await axios(`${API_URL}/observations`, {
        params: {
          temporal_resolution: 'yearly',
          spatial_resolution: 'country',
          metric_id: ARTMetricID,
          lag_allowed: 1000,
          start: today,
          end: today,
        },
      })

      const incidenceObj = {}
      incidence.data.data.forEach(
        obs => (incidenceObj[obs.place_iso] = obs.value)
      )

      const epiDataObj = {}
      art.data.data.forEach(obs => {
        epiDataObj[obs.place_iso] = {
          incidence: incidenceObj[obs.place_iso],
          art: obs.value,
        }
      })

      const mergedData = Object.values(
        props.pageContext.observations.mostRecent
      ).map(row => ({
        ...row,
        ...epiDataObj[row.place_id],
      }))

      mergedData.sort((a, b) => a.place_name.localeCompare(b.place_name))

      setData(mergedData)
    }

    getEpiMetrics()
  }, [])

  const [tableData, setTableData] = useState()

  const allCountries = Object.values(props.pageContext.observations.mostRecent)
  const [selectedCountries, setSelectedCountries] = useState(
    new Set(allCountries.map(c => c.place_id))
  )

  const countriesByRegion = {}
  Object.entries(props.pageContext.regions).map(([ISO2, region]) => {
    if (region !== 'Other region')
      countriesByRegion[region] = [...(countriesByRegion[region] || []), ISO2]
  })

  console.log(props.pageContext.pepfar)
  const [filteredData, setFilteredData] = useState(data)

  useEffect(() => {
    setFilteredData(
      data && data.filter(row => selectedCountries.has(row.place_id))
    )
  }, [data, selectedCountries])

  const contextValue = {
    pepfar: props.pageContext.pepfar,
    regions: countriesByRegion,
    allCountries,
    selectedCountries,
    setSelectedCountries,
  }

  console.log(selectedCountries)
  console.log(filteredData)
  console.log(setTableData)

  return (
    <TableHolder>
      <FilterFrame {...{ contextValue }}>
        <CountryFilters expanded notOptional />
      </FilterFrame>
      <AdoptionTable {...{ setData, tableData, ...props }} />
      <Paginator data={filteredData} {...{ setTableData }} />
    </TableHolder>
  )
}

export default AdoptionTableSection
