import React from 'react'
import styled, { keyframes } from 'styled-components'
import Flag from '../Flag'

import Bar from './Bar'

const labelFontSize = 5.5
const labelPad = 10
const lineHeight = 1.5
const flagHolderWidth = 25

const Label = styled.text`
  transition: 250ms ease;
  font-size: ${labelFontSize}px;
  text-anchor: middle;
  fill: ${props => props.theme.colorBlack};
`
const fadeInAnimation = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1; }
`
const AnimationGroup = styled.g`
  transition: 250ms ease;
  animation: ${fadeInAnimation} 500ms ease;
`
const FlagHolder = styled.foreignObject`
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  pointer-events: none;
  transition: 250ms ease;
`

const Region = ({ name, region, index, dim, highlightCountry }) => {
  const barWidth = dim.regionWidth / Object.keys(region.adoption).length

  const regionStart =
    dim.axes.x.start + index * dim.regionWidth + (index + 1) * dim.plotGap

  const regionSorted = Object.entries(region.adoption).sort(
    (a, b) => b[0] - a[0]
  )

  return (
    <AnimationGroup
      key={name}
      style={{ color: 'blue', transform: `translateX(${regionStart}px)` }}
    >
      <Label
        key={name}
        style={{
          transform: `translateX(${
            (barWidth * Object.keys(region.adoption).length) / 2
          }px)`,
        }}
        x={0}
        y={dim.axes.y.start + labelPad}
      >
        <tspan>{name.split(' ').slice(0, 2).join(' ')}</tspan>
        <tspan x={0} dy={labelFontSize * lineHeight}>
          {name.split(' ').slice(2, 5).join(' ')}
        </tspan>
        <tspan x={0} dy={labelFontSize * lineHeight}>
          {name.split(' ').slice(5).join(' ')}
        </tspan>
      </Label>
      {regionSorted.map(([status, countries], barIndex) => (
        <Bar
          x={barIndex * barWidth}
          key={barIndex}
          width={barWidth}
          value={countries.length / region.countryCount}
          yScale={dim.axes.y.scale}
          {...{ status, region, countries }}
        />
      ))}
      {highlightCountry &&
        regionSorted.map(
          ([_, countries], barIndex) =>
            countries.map(c => c.place_id).includes(highlightCountry.key) && (
              <FlagHolder
                x={barIndex * barWidth + barWidth / 2 - flagHolderWidth / 2}
                y={
                  dim.axes.y.scale(countries.length / region.countryCount / 2) -
                  9
                }
                width={flagHolderWidth}
              >
                <Flag
                  style={{ height: 20, margin: 'auto' }}
                  iso2={highlightCountry.key}
                />
              </FlagHolder>
            )
        )}
    </AnimationGroup>
  )
}

export default Region
