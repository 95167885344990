import React from 'react'
import styled from 'styled-components'
import * as d3 from 'd3/dist/d3.min'

import Region from './Region'
import Axes from './Axes'
import PlotControls from './PlotControls'
import Legend from './Legend'
import NullPattern from '../NullPattern'
import RegionalAdoptionHeader from './RegionalAdoptionHeader'

const PlotContainer = styled.div`
  width: 100%;
`
const Svg = styled.svg`
  overflow: visible;
  /* border: 1px solid #000; */
`
// const Title = styled.text`
//   font-size: 9px;
//   font-weight: 800;
//   text-anchor: middle;
//   fill: ${props => props.theme.colorBlack};
// `

const RegionalAdoptionPlot = props => {
  const regions = props.pageContext.regions
  const adoptionByRegion = {}

  const [selectedRegions, setSelectedRegions] = React.useState({
    all: [
      { id: 0, label: 'Global' },
      { id: 2, label: 'Eastern and southern Africa' },
      { id: 3, label: 'Middle East and North Africa' },
      { id: 4, label: 'Asia and the Pacific' },
      { id: 5, label: 'Latin America and the Caribbean' },
      { id: 6, label: 'Eastern Europe and central Asia' },
      { id: 7, label: 'Western and central Europe and North America' },
      { id: 8, label: 'Western and central Africa' },
    ],
    selected: [{ id: 0, label: 'Global' }],
  })

  // const [highlightCountry, setHighlightCountry] = React.useState({
  //   all: Object.values(Object.values(props.pageContext.observations)[0])
  //     .sort((a, b) => a.place_name.localeCompare(b.place_name))
  //     .map(country => ({ id: country.place_id, label: country.place_name })),
  //   selected: [],
  // })

  const [highlightCountry, setHighlightCountry] = React.useState()

  const countries = Object.values(props.pageContext.observations.mostRecent)
    .sort((a, b) => a.place_name.localeCompare(b.place_name))
    .filter(country =>
      selectedRegions.selected
        .map(i => i.label)
        .some(region => [regions[country.place_id], 'Global'].includes(region))
    )
    .map(country => ({
      key: country.place_id,
      label: country.place_name,
    }))

  // if the currently highlighted country is not
  // in the list of countries available for selection
  // reset the highlighted country
  React.useEffect(() => {
    if (
      highlightCountry &&
      !countries.map(c => c.label).includes(highlightCountry.label)
    )
      setHighlightCountry('')
  }, [countries])

  const partialAllowed = Object.keys(props.pageContext.values).includes(
    'Partially adopted'
  )

  const adoptionStatuses = {
    // include 0.5 only if "partial" is
    // included in the codebook for this polcy
    ...(partialAllowed && { 0.5: [] }),
    null: [],
    0: [],
    1: [],
  }

  Object.entries(props.pageContext.observations.mostRecent).forEach(
    ([iso2, country]) => {
      if (selectedRegions.selected.map(i => i.label).includes('Global')) {
        adoptionByRegion.Global = adoptionByRegion.Global || {
          adoption: { ...adoptionStatuses },
          pageContext: props.pageContext,
          name: 'Global',
          countryCount: 0,
        }
        adoptionByRegion.Global.adoption[country.adoption] = [
          ...adoptionByRegion.Global.adoption[country.adoption],
          country,
        ]
        adoptionByRegion.Global.countryCount++
      }
      if (selectedRegions.selected.map(i => i.label).includes(regions[iso2])) {
        adoptionByRegion[regions[iso2]] = adoptionByRegion[regions[iso2]] || {
          adoption: { ...adoptionStatuses },
          pageContext: props.pageContext,
          name: regions[iso2],
          countryCount: 0,
        }
        adoptionByRegion[regions[iso2]].adoption[country.adoption] = [
          ...adoptionByRegion[regions[iso2]].adoption[country.adoption],
          country,
        ]
        adoptionByRegion[regions[iso2]].countryCount++
      }
    }
  )

  const regionCount = Object.keys(adoptionByRegion).length

  const dim = {
    width: 500,
    height: 200,

    dynamicPadding:
      248.625 -
      102.7917 * regionCount +
      14.875 * regionCount ** 2 -
      0.7083333 * regionCount ** 3,

    // points used for regression: (1, 15), (4, 10), (8, 6)
    plotGap: 17.2 - 2.333 * regionCount + 0.13333 * regionCount ** 2,
  }

  dim.padding = {
    top: 4,
    bottom: 30,
    left: dim.dynamicPadding + 15,
    right: dim.dynamicPadding + 40,
  }

  dim.axes = {
    x: {
      start: dim.padding.left,
      end: dim.width - dim.padding.right,
      length: dim.width - dim.padding.right - dim.padding.left,
    },
    y: {
      start: dim.height - dim.padding.bottom,
      end: dim.padding.top,
      length: dim.height - dim.padding.bottom - dim.padding.top,
    },
  }

  let maxBar = 0
  Object.values(adoptionByRegion).forEach(region =>
    Object.values(region.adoption).forEach(
      bar =>
        (maxBar =
          bar.length / region.countryCount > maxBar
            ? bar.length / region.countryCount
            : maxBar)
    )
  )

  dim.plotMax = Math.ceil(maxBar * 10.1) / 10
  if (dim.plotMax > 100) dim.plotMax = 100

  dim.axes.y.scale = d3
    .scaleLinear()
    .domain([0, dim.plotMax])
    .range([dim.axes.y.start, dim.axes.y.end])

  dim.regionWidth =
    (dim.axes.x.length - dim.plotGap) / regionCount - dim.plotGap

  return (
    <div>
      <RegionalAdoptionHeader {...props}>
        <PlotControls
          {...{
            selectedRegions,
            setSelectedRegions,
            countries,
            highlightCountry,
            setHighlightCountry,
          }}
        />
      </RegionalAdoptionHeader>
      <PlotContainer>
        <Svg viewBox={`0 0 ${dim.width} ${dim.height}`}>
          {/* <Title x={dim.axes.x.start + dim.axes.x.length / 2} y={10}>
            {props.pageContext.abbr}{' '}
            {selectedRegions.selected.length === 1
              ? `${selectedRegions.selected[0].label} adoption status`
              : `adoption status across ${selectedRegions.selected.length} UNAIDS regions`}
          </Title> */}
          <defs>
            <NullPattern id="nullPattern" scale=".19" />
          </defs>
          {Object.entries(adoptionByRegion).map(([name, region], index) => (
            <Region
              key={name}
              {...{ name, region, index, dim, highlightCountry }}
            />
          ))}
          <Axes x={dim.axes.x} y={dim.axes.y} plotMax={dim.plotMax} />
          <Legend partialAllowed={partialAllowed} />
        </Svg>
      </PlotContainer>
    </div>
  )
}

export default RegionalAdoptionPlot
