import React from 'react'
import styled from 'styled-components'

const Path = styled.path`
  stroke-width: 4;
  stroke: ${props => props.theme.colorMiddleGray};
  fill: none;
`

const NullPattern = ({ id, scale }) => (
  <pattern
    id={id}
    width="10"
    height="10"
    patternUnits="userSpaceOnUse"
    patternTransform={`scale(${scale}) rotate(155)`}
  >
    <Path d="M0 10h20z" />
  </pattern>
)

export default NullPattern
