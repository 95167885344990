import React from 'react'
import styled from 'styled-components'
import { useTheme } from 'styled-components'

const keyHeight = 14

const Label = styled.text`
  alignment-baseline: middle;
  transform: translateY(1px);
  font-size: 12px;
`
const Key = styled.rect`
  stroke-width: 0.25;
  stroke: ${props => props.theme.colorMiddleGray};
`

const Legend = ({ partialAllowed }) => {
  const theme = useTheme()

  const lineStyles = {
    null: {
      fill: 'url(#nullPattern)',
    },
    0: {
      fill: theme.colorVeryFew,
    },
    0.5: {
      fill: theme.colorSome,
    },
    1: {
      fill: theme.colorMost,
    },
  }

  return (
    <g transform="translate(410, 20) scale(0.5)">
      <Key style={lineStyles[1]} y={0} width={keyHeight} height={keyHeight} />
      <Label x={keyHeight * 1.5} y={keyHeight / 2}>
        Adopted
      </Label>
      <Key
        style={lineStyles[0]}
        y={keyHeight * 1.75}
        width={keyHeight}
        height={keyHeight}
      />
      <Label x={keyHeight * 1.5} y={keyHeight * 2.25}>
        Not Adopted
      </Label>
      {partialAllowed ? (
        <>
          <Key
            style={lineStyles[0.5]}
            y={keyHeight * 3.5}
            width={keyHeight}
            height={keyHeight}
          />
          <Label x={keyHeight * 1.5} y={keyHeight * 4}>
            Partial
          </Label>
          <Key
            style={lineStyles[null]}
            y={keyHeight * 5.25}
            width={keyHeight}
            height={keyHeight}
          />
          <Label x={keyHeight * 1.5} y={keyHeight * 6}>
            <tspan>Data not</tspan>
            <tspan x={keyHeight * 1.5} dy={12 * 1.3}>
              available
            </tspan>
          </Label>
        </>
      ) : (
        <>
          <Key
            style={lineStyles[null]}
            y={keyHeight * 3.5}
            width={keyHeight}
            height={keyHeight}
          />
          <Label x={keyHeight * 1.5} y={keyHeight * 4.25}>
            <tspan>Data not</tspan>
            <tspan x={keyHeight * 1.5} dy={12 * 1.3}>
              available
            </tspan>
          </Label>
        </>
      )}
    </g>
  )
}

export default Legend
