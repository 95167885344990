import React from 'react'
import styled from 'styled-components'
import * as d3 from 'd3/dist/d3.min'

const Svg = styled.svg`
  overflow: visible;
  margin: -15px;
  /* border: 1px solid #000; */
`
const Arc = styled.path`
  fill: ${props =>
    ({
      null: 'url(#nullPattern)',
      0: props.theme.colorVeryFew,
      0.5: props.theme.colorSome,
      1: props.theme.colorMost,
    }[props.status])};
  stroke: ${props =>
    ({
      null: props.theme.colorMiddleGray,
      0: props.theme.colorVeryFew,
      0.5: props.theme.colorSome,
      1: props.theme.colorMost,
    }[props.status])};
  stroke-width: 0.5;
`
const Label = styled.text`
  text-anchor: middle;
  alignment-baseline: middle;
  font-size: 7px;
`

const DoughnutPlot = ({ adoption, countryCount, values }) => {
  const dim = {
    width: 150,
    height: 150,
    margin: 20,
  }

  dim.radius = { outer: Math.min(dim.width, dim.height) / 2 - dim.margin }
  dim.radius.inner = dim.radius.outer * 0.5
  dim.radius.label = dim.radius.outer * 1.2

  const pie = d3
    .pie()
    .value(d => d.value)
    .sort((a, b) => Number(b.key) - Number(a.key))(d3.entries(adoption))

  return (
    <Svg viewBox={`0 0 ${dim.width} ${dim.height}`}>
      {pie.map(slice => (
        <g
          key={slice.data.key}
          transform={`translate(${dim.width / 2}, ${
            dim.height / 2
          }) rotate(-90)`}
        >
          <Arc
            d={d3
              .arc()
              .innerRadius(dim.radius.inner)
              .outerRadius(dim.radius.outer)(slice)}
            status={slice.data.key}
          />
          {slice.data.value / countryCount > 0.03 && (
            <Label
              transform={`translate(${d3
                .arc()
                .innerRadius(dim.radius.label)
                .outerRadius(dim.radius.label)
                .centroid(slice)}) rotate(90)`}
            >
              {Math.round((slice.data.value / countryCount) * 100)}%
            </Label>
          )}
        </g>
      ))}
    </Svg>
  )
}

export default DoughnutPlot
