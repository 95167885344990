import React from 'react'
import styled from 'styled-components'

import { Link } from 'gatsby'
import LazyTippy from '../LazyTippy'

import PolicyAbbr from '../PolicyAbbr'
import AdoptionStatusBlock from '../AdoptionStatusBlock'
import AdoptionStatusTerm from '../AdoptionStatusTerm'
import Flag from '../Flag'

const StyledTippy = styled(LazyTippy)`
  min-width: 20rem;
`
const Header = styled.header`
  background: ${props => props.theme.colorEvenLighterGray};
  padding: 15px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 800;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
`
const Row = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`
const CountryList = styled.section`
  max-height: 13em;
  overflow-y: scroll;
  padding: 15px;
`
const Year = styled.span`
  color: ${props => props.theme.colorMiddleGray};
  margin-left: auto;
`
const Region = styled.span`
  color: ${props => props.theme.colorBlack};
  max-width: 14em;
`
const StatusBlock = styled(AdoptionStatusBlock)`
  font-size: 14px;
  box-shadow: none;
`
const Abbr = styled(PolicyAbbr)`
  font-size: 18px;
`
const StyledFlag = styled(Flag)`
  width: 3em;
  margin-right: 15px;
`
const CountryLink = styled(Link)`
  font-weight: 800;
  display: flex;
  align-items: center;
`

const TooltipContent = ({ year, bar, status }) => (
  <>
    <Header>
      <Row>
        <Abbr>{bar.pageContext.abbr}</Abbr>
        <StatusBlock status={status}>
          <AdoptionStatusTerm uppercase status={status} />
        </StatusBlock>
      </Row>
      <Row>
        <Region>{bar.region}</Region>
        <Year>{year}</Year>
      </Row>
    </Header>
    <CountryList>
      <p>
        <strong>
          {bar.countries.length} of {bar.countryCount} countries
        </strong>
      </p>
      {bar.countries
        .sort((a, b) => a.place_name.localeCompare(b.place_name))
        .map(country => (
          <CountryLink
            key={country.place_id}
            to={`/${country.place_id.toLowerCase()}`}
          >
            <StyledFlag iso2={country.place_id} />
            {country.place_name}
          </CountryLink>
        ))}
    </CountryList>
  </>
)

const Tooltip = ({ year, bar, status, children }) => {
  let tooltipParent = 'parent'
  if (typeof document !== 'undefined') {
    tooltipParent = document.body
  }

  return (
    <StyledTippy
      theme={'light'}
      appendTo={tooltipParent}
      interactive={true}
      interactiveBorder={10}
      content={<TooltipContent {...{ year, bar, status }} />}
      trigger="click"
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              // the 10 accounts for the height of the little arrow
              offset: ({ reference }) => [0, 10 - reference.height / 2],
            },
          },
        ],
      }}
    >
      {children}
    </StyledTippy>
  )
}

export default Tooltip
