import axios from 'axios'

const API_URL = process.env.GATSBY_METRICS_API_URL

const getIncidenceData = async pageContext => {
  // find correct metric ID
  const metric_id = pageContext.epiMetrics.find(
    m => m.name === 'infections Five year percent change'
  ).value

  // request data for current date, but with 1000 years lag allowed
  const today = new Date().toISOString().split('T')[0]
  const incidence = await axios(`${API_URL}/observations`, {
    params: {
      temporal_resolution: 'yearly',
      spatial_resolution: 'country',
      metric_id: metric_id,
      lag_allowed: 1000,
      start: today,
      end: today,
    },
  })

  // package it all neat and tidy
  // { ISO: { place_name: '', incidence: 0}}
  const incidenceObject = {}
  incidence.data.data.forEach(obs => {
    // have to filter out 'Global'
    if (obs.place_name !== 'Global')
      incidenceObject[obs.place_iso] = {
        place_name: obs.place_name,
        incidence: obs.value,
      }
  })

  return incidenceObject
}

export default getIncidenceData
