import React from 'react'
import styled from 'styled-components'

import PolicyAbbr from '../PolicyAbbr'
import ExpandingSection from '../ExpandingSection/ExpandingSection'
import CodebookSection from './CodebookSection'

import ExpandIndicator from './ExpandIndicator'

const subindicatorPadding = 20

const Section = styled.section`
  background-color: ${props => props.theme.colorLightestBackgroundBlue};
  margin: 20px 40px;
  padding-bottom: 1px;
  border-radius: 5px;
  @media (max-width: 768px) {
    margin: 20px 0;
  }
`
const Subindicator = styled(Section)`
  margin: 0 ${subindicatorPadding}px 20px ${subindicatorPadding}px;
`
const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  @media (max-width: 768px) {
    padding: 20px 20px !important;
  }
`

const DetailTable = props => {
  const [expanders, setExpanders] = React.useState(() => {
    const status = { main: true }
    if (props.pageContext.subindicators)
      Object.keys(props.pageContext.subindicators).forEach(abbr => {
        status[abbr] = false
      })

    return status
  })

  const onClick = abbr => {
    setExpanders(prev => ({ ...prev, [abbr]: !prev[abbr] }))
  }

  // set the width of the first column explicitly
  const colWidth = { parent: 222 }
  // calculate the width of the first column in the nested table
  colWidth.nested = colWidth.parent - subindicatorPadding

  const header = (
    <Header style={{ padding: '20px' }}>
      <h4>
        <PolicyAbbr>{props.pageContext.abbr}</PolicyAbbr>
        {props.pageContext.name}
      </h4>
      {props.collapsible && <ExpandIndicator open={expanders.main} />}
    </Header>
  )

  const table = (
    <>
      <CodebookSection
        indicator={props.pageContext}
        colWidth={colWidth.parent}
      />
      {props.pageContext.subindicators &&
        Object.entries(props.pageContext.subindicators).map(
          ([abbr, subindicator]) => (
            <Subindicator key={abbr}>
              <ExpandingSection
                open={expanders[abbr]}
                onOpen={() => onClick(abbr)}
                onClose={() => onClick(abbr)}
              >
                <Header>
                  <h4>
                    <PolicyAbbr>{abbr}</PolicyAbbr>
                    {subindicator.name}
                  </h4>
                  <ExpandIndicator open={expanders[abbr]} />
                </Header>
                <CodebookSection
                  indicator={subindicator}
                  colWidth={colWidth.nested}
                />
              </ExpandingSection>
            </Subindicator>
          )
        )}
    </>
  )

  if (props.collapsible)
    return (
      <Section>
        <ExpandingSection
          open={expanders.main}
          onOpen={() => onClick('main')}
          onClose={() => onClick('main')}
        >
          {header}
          {table}
        </ExpandingSection>
      </Section>
    )
  else
    return (
      <Section>
        {header}
        {table}
      </Section>
    )
}

export default DetailTable
