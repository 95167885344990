import React from 'react'
import styled from 'styled-components'

import MultiSelect from '@kenshooui/react-multi-select'
import '@kenshooui/react-multi-select/dist/style.css'

import ExpandingSection from '../../ExpandingSection/ExpandingSection'

const Filter = styled.div`
  margin-top: 0.5rem;
  /* width: 24rem; */
`
const ButtonLabel = styled.span`
  display: block;
  padding: 0.25em 1em;
  appearance: none;
  padding: 4px 8px;
  padding-right: 50px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 216 216' style='enable-background:new 0 0 216 216;' xml:space='preserve'%3E%3Crect style='fill:%23DDDDDD;' width='216' height='216'/%3E%3Cpolygon style='fill:%2358595B;' points='72,92.25 144,92.25 108,132.75 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center right;
  line-height: normal;
  border: 1px solid #aaa;
  border-radius: 5px;
  text-align: left;
`
const FilterFrame = styled.div`
  border: 1px solid #76767633;
  width: 400px;
  @media (max-width: 768px) {
    width: 350px;
  }
`

const RadioSelect = ({
  state,
  stateSetter,
  defaultLabel,
  defaultSelection,
}) => {
  const [filterOpen, setFilterOpen] = React.useState(false)

  const onSelect = (items, stateSetter) => {
    if (items.length === 0)
      stateSetter(prev => ({
        ...prev,
        selected: defaultSelection,
      }))
    else
      stateSetter(prev => {
        let newItems
        if (prev.selected[0]) {
          newItems = items.filter(item => item.id !== prev.selected[0].id)
        } else {
          newItems = items
        }
        return { ...prev, selected: newItems }
      })
  }

  return (
    <Filter>
      <ExpandingSection
        floating
        open={filterOpen}
        onOpen={() => setFilterOpen(true)}
        onClose={() => setFilterOpen(false)}
      >
        <ButtonLabel>
          {state.selected[0] ? state.selected[0].label : defaultLabel}
        </ButtonLabel>
        <FilterFrame>
          <MultiSelect
            showSelectedItems={false}
            showSelectAll={false}
            maxSelectedItems={2}
            items={state.all}
            selectedItems={state.selected}
            onChange={items => onSelect(items, stateSetter)}
          />
        </FilterFrame>
      </ExpandingSection>
    </Filter>
  )
}

export default RadioSelect
