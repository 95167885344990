import React from 'react'
import styled from 'styled-components'
import DistributionPlot from '../DistributionPlot/DistributionPlot'
// import AdoptionGrid from './AdoptionGrid'
import DoughnutLegend from './DoughnutPlot/DoughnutLegend'
import DoughnutPlot from './DoughnutPlot/DoughnutPlot'
import InfoBubble from '../../InfoBubble/InfoBubble'

const Section = styled.section`
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid ${props => props.theme.colorPrimaryBlue3};
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const Column = styled.div`
  flex-basis: calc(50% - 10px);
  display: flex;
`
const MainColumn = styled(Column)`
  position: relative;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  &:nth-of-type(1) {
    flex-basis: 42%;
  }
  &:nth-of-type(2) {
    flex-basis: 56%;
    @media (max-width: 768px) {
      margin-top: 30px;
    }
  }
  &:first-of-type::after {
    content: '';
    position: absolute;
    top: 0px;
    right: 0;
    width: 1px;
    bottom: 0;
    background-color: ${props => props.theme.colorBlack};
    @media (max-width: 768px) {
      background: none;
    }
  }
`
const Container = styled.div`
  width: 100%;
`

const DoughnutContainer = styled(Container)`
  @media (max-width: 768px) {
    width: 70%;
    margin: auto;
  }
`

const SubTitle = styled.span`
  display: block;
  font-size: 16px;
  margin-bottom: 24px;
  margin-top: 5px;
`

const IntroSection = props => {
  let countryCount = 0
  const adoption = {}
  Object.values(props.pageContext.observations.mostRecent).forEach(country => {
    adoption[country.adoption] = adoption[country.adoption] + 1 || 1
    countryCount++
  })

  return (
    <Section>
      <MainColumn>
        <Column>
          <Container>
            <h4>{props.pageContext.abbr} Adoption Status</h4>
            <SubTitle>(out of {countryCount} countries)</SubTitle>
            <DoughnutLegend
              {...{ adoption, countryCount }}
              values={props.pageContext.values}
            />
          </Container>
        </Column>
        <Column>
          <DoughnutContainer>
            {/* <AdoptionGrid
              adoption={adoption}
              values={props.pageContext.values}
            /> */}
            <DoughnutPlot
              {...{ adoption, countryCount }}
              values={props.pageContext.values}
            />
          </DoughnutContainer>
        </Column>
      </MainColumn>
      <MainColumn>
        <Container>
          <h4>
            {props.pageContext.abbr} Adoption Status and HIV Infections 5 Year
            Percent Change{' '}
            <InfoBubble>
              This plot shows the distribution of the 5 year percent change in
              HIV infections, across all countries, based on policy adoption
              status. It is truncated to the 90th percentile.
            </InfoBubble>
          </h4>
          <DistributionPlot {...{ ...props, adoption }} />
        </Container>
      </MainColumn>
    </Section>
  )
}

export default IntroSection
