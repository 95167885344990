import React from 'react'
import styled from 'styled-components'

const Spine = styled.line`
  stroke: ${props => props.theme.colorMiddleGray};
  stroke-linecap: square;
  stroke-width: 2.5px;
`
// const YLabel = styled.text`
//   font-size: 12px;
//   text-anchor: end;
//   alignment-baseline: middle;
//   fill: ${props => props.theme.colorBlack};
//   transform: translateY(1px);
// `
const XLabel = styled.text`
  font-size: 12px;
  text-anchor: middle;
  fill: ${props => props.theme.colorBlack};
  transform: translateY(1px);
`
const AxisLabel = styled.text`
  font-size: 12px;
  text-anchor: middle;
  fill: ${props => props.theme.colorBlack};
  font-weight: 800;
`

// const yTickPad = 10
const xTickPad = 18
const xLabelPad = 40
const yLabelPad = 15

const Axes = ({ x, y, bins }) => {
  const xLabels = bins.binWidth && {
    start: { index: 1, x: bins.lines[0][0].x },
    mid: {
      // for labeling the actual middle value
      // index: Math.floor(bins.nBins / 2),
      // x: Number(bins.lines[0][Math.floor(bins.nBins / 2)].x),
      // for labeling 0%... I think this one is more useful.
      index: bins.lines[0].findIndex(point => point.x === 0),
      x: 0,
    },
    end: {
      index: bins.lines[0].length,
      x: bins.lines[0].slice(-1)[0].x,
    },
  }

  return (
    <>
      {/* Y-Axis */}
      <Spine x1={x.start} x2={x.end} y1={y.start} y2={y.start} />
      <AxisLabel
        transform={`translate(${x.start - yLabelPad}, ${
          y.start - y.length / 2
        }) rotate(-90)`}
      >
        # of countries
      </AxisLabel>
      {/* {bins.binWidth && (
        <>
          <YLabel x={x.start - yTickPad} y={y.start}>
            0
          </YLabel>
          <YLabel
            x={x.start - yTickPad}
            y={y.scale(Math.round(bins.maxCount / 2))}
          >
            {Math.round(bins.maxCount / 2)}
          </YLabel>
          <YLabel x={x.start - yTickPad} y={y.end}>
            {bins.maxCount}
          </YLabel>
        </>
      )} */}
      {/* X-Axis */}
      <Spine x1={x.start} x2={x.start} y1={y.start} y2={y.end} />
      <AxisLabel x={x.start + x.length / 2} y={y.start + xLabelPad}>
        HIV Infections 5 Year Percent Change
      </AxisLabel>
      {bins.binWidth && (
        <>
          <XLabel x={x.scale(xLabels.start.index)} y={y.start + xTickPad}>
            {/* {Math.round(xLabels.start.x)}% */}
            fewer infections
          </XLabel>
          <XLabel x={x.scale(xLabels.mid.index)} y={y.start + xTickPad}>
            {/* {Math.round(xLabels.mid.x)}% */}
            no change
          </XLabel>
          <XLabel x={x.scale(xLabels.end.index)} y={y.start + xTickPad}>
            {/* {Math.round(xLabels.end.x)}% */}
            more infections
          </XLabel>
        </>
      )}
    </>
  )
}

export default Axes
