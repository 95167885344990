import React from 'react'
import styled from 'styled-components'

import * as d3 from 'd3/dist/d3.min'

import Axes from './Axes'
import YearBar from './YearBar'
import ConnectingLines from './ConnectingLines'
import Legend from './Legend'
import PlotControls from './PlotControls/PlotControls'
import NullPattern from '../NullPattern'

const Svg = styled.svg`
  overflow: visible;
  /* border: 1px solid #000; */
`
const PlotContainer = styled.div`
  width: 100%;
`
// const Title = styled.text`
//   font-size: 9px;
//   font-weight: 800;
//   text-anchor: middle;
//   fill: ${props => props.theme.colorBlack};
// `

const AdoptionOverTimePlot = props => {
  const regions = props.pageContext.regions
  const partialAllowed = Object.keys(props.pageContext.values).includes(
    'Partially adopted'
  )

  // should replace this by dynamically getting regions
  const [selectedRegions, setSelectedRegions] = React.useState({
    all: [
      { id: 0, label: 'Global' },
      { id: 2, label: 'Eastern and southern Africa' },
      { id: 3, label: 'Middle East and North Africa' },
      { id: 4, label: 'Asia and the Pacific' },
      { id: 5, label: 'Latin America and the Caribbean' },
      { id: 6, label: 'Eastern Europe and central Asia' },
      { id: 7, label: 'Western and central Europe and North America' },
      { id: 8, label: 'Western and central Africa' },
    ],
    selected: [{ id: 0, label: 'Global' }],
  })

  const countries = Object.values(props.pageContext.observations.mostRecent)
    .sort((a, b) => a.place_name.localeCompare(b.place_name))
    .filter(country =>
      selectedRegions.selected
        .map(i => i.label)
        .some(region => [regions[country.place_id], 'Global'].includes(region))
    )
    .map(country => ({
      key: country.place_id,
      label: country.place_name,
    }))

  const [highlightCountry, setHighlightCountry] = React.useState()

  // if the currently highlighted country is not
  // in the list of countries available for selection
  // reset the highlighted country
  React.useEffect(() => {
    if (
      highlightCountry &&
      !countries.map(c => c.label).includes(highlightCountry.label)
    )
      setHighlightCountry('')
  }, [countries])

  const dim = {
    width: 500,
    height: 185,
    padding: {
      top: 10,
      left: 80,
      bottom: 30,
      right: 70,
    },
    // barWidth: 40,
    barXPadding: 60,
  }

  dim.axes = {
    x: {
      start: dim.padding.left,
      end: dim.width - dim.padding.right,
      length: dim.width - dim.padding.right - dim.padding.left,
    },
    y: {
      start: dim.height - dim.padding.bottom,
      end: dim.padding.top,
      length: dim.height - dim.padding.bottom - dim.padding.top,
    },
  }

  const { mostRecent: _, ...years } = props.pageContext.observations

  const adoptionStatuses = {
    // include 0.5 only if "partial" is
    // included in the codebook for this polcy
    ...(partialAllowed && { 0.5: [] }),
    null: [],
    0: [],
    1: [],
  }

  const stacks = {}
  Object.entries(years).forEach(([year, data]) => {
    const adoption = { ...adoptionStatuses }
    let countryCount = 0
    if (selectedRegions.selected.map(i => i.label).includes('Global')) {
      Object.values(data).forEach(country => {
        adoption[country.adoption] = [...adoption[country.adoption], country]
        countryCount++
      })
    } else {
      Object.entries(data).forEach(([iso2, country]) => {
        if (
          selectedRegions.selected.map(i => i.label).includes(regions[iso2])
        ) {
          adoption[country.adoption] = [...adoption[country.adoption], country]
          countryCount++
        }
      })
    }

    const stackKeys = Object.keys(adoption).sort((a, b) => {
      if (isNaN(a)) {
        return -1
      } else {
        return a - b
      }
    })

    stacks[year] = {}
    stackKeys.forEach((key, index) => {
      const start = stacks[year][stackKeys[index - 1]]?.end || 0
      const height = adoption[key].length / countryCount
      stacks[year][key] = {
        start,
        height,
        end: start + height,
        countries: adoption[key],
        pageContext: props.pageContext,
        region: selectedRegions.selected[0].label,
        countryCount,
      }
    })
  })

  const yearCount = Object.keys(stacks).length

  // const barPadding = 30
  dim.barWidth = (dim.axes.x.length * 0.6) / yearCount

  dim.axes.y.scale = d3
    .scaleLinear()
    .domain([0, 1])
    .range([dim.axes.y.start, dim.axes.y.end])

  dim.axes.x.scale = d3
    .scaleLinear()
    .domain([0, yearCount - 1])
    .range([
      dim.axes.x.start + dim.barXPadding,
      dim.axes.x.end - dim.barXPadding,
    ])

  // console.log(stacks)

  // const yearsArray = Object.keys(years)

  return (
    <div>
      <h2>
        {selectedRegions.selected[0].label === 'Global'
          ? 'Global adoption'
          : 'Adoption'}{' '}
        status over time
        {selectedRegions.selected[0].label !== 'Global' &&
          `, ${selectedRegions.selected[0].label}`}
      </h2>
      <p>
        View {props.pageContext.abbr} global adoption status or status by region
        over the past four years.
      </p>
      <PlotControls
        {...{
          selectedRegions,
          setSelectedRegions,
          countries,
          highlightCountry,
          setHighlightCountry,
        }}
      />
      <PlotContainer>
        <Svg viewBox={`0 0 ${dim.width} ${dim.height}`}>
          {/* <Title x={dim.axes.x.start + dim.axes.x.length / 2} y={10}>
            {props.pageContext.abbr} {selectedRegions.selected[0].label}{' '}
            adoption status
          </Title> */}
          <defs>
            <NullPattern id="nullPattern" scale={0.1} />
          </defs>
          {Object.entries(stacks).map(([year, stack], index) => (
            <React.Fragment key={year}>
              <ConnectingLines startYear={year} {...{ stacks, index, dim }} />
              <YearBar {...{ year, stack, index, dim, highlightCountry }} />
            </React.Fragment>
          ))}
          <Axes
            x={dim.axes.x}
            y={dim.axes.y}
            region={selectedRegions.selected[0].label}
          />
          <Legend partialAllowed={partialAllowed} />
        </Svg>
      </PlotContainer>
    </div>
  )
}

export default AdoptionOverTimePlot
