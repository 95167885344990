import React from 'react'
import styled from 'styled-components'
import PolicyAbbr from './PolicyAbbr'

const SectionContainer = styled.section`
  margin: 2rem 0;
  padding: 1rem 0;
  border-top: 5px solid ${props => props.theme.colorPrimaryBlue1};
`

const Section = props => (
  <SectionContainer className={props.className}>
    {!props.skipHeader && (
      <h4>
        <PolicyAbbr>{props.pageContext.abbr}</PolicyAbbr>
        {props.pageContext.name}
      </h4>
    )}
    {props.children}
  </SectionContainer>
)

export default Section
