import React from 'react'
import styled from 'styled-components'
import Tooltip from './Tooltip'

const Label = styled.text`
  font-size: 4px;
  text-anchor: middle;
  fill: ${props => props.theme.colorMiddleGray};
  transition: 250ms ease;
`
const Rect = styled.rect`
  transition: 250ms ease;
  cursor: pointer;
  fill: ${props =>
    ({
      null: 'url(#nullPattern)',
      0: props.theme.colorVeryFew,
      0.5: props.theme.colorSome,
      1: props.theme.colorMost,
    }[props.status])};
  stroke-width: 0.25;
  stroke: ${props => props.theme.colorMiddleGray};
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: 0.25px solid blue;
  }
`

const labelPad = 2
const emptyBarHeight = 0.75

const Bar = ({ x, width, value, status, yScale, region, countries }) => (
  <>
    <Tooltip {...{ region, countries, status }}>
      <Rect
        x={x}
        y={value ? yScale(value) : yScale(0) - emptyBarHeight}
        width={width}
        height={yScale(0) - yScale(value) || emptyBarHeight}
        status={status}
      />
    </Tooltip>
    <Label
      style={{
        transform: `translate(${x + width / 2}px, ${
          yScale(value) - labelPad
        }px)`,
      }}
    >
      {Math.round(value * 100)}%
    </Label>
  </>
)

export default Bar
