import React from 'react'
import styled from 'styled-components'
import NullPattern from '../../NullPattern'

const KeyRect = styled.rect`
  fill: ${props =>
    ({
      null: 'url(#nullPattern)',
      0: props.theme.colorVeryFew,
      0.5: props.theme.colorSome,
      1: props.theme.colorMost,
    }[props.status])};
  stroke: ${props => props.status === null && props.theme.colorMiddleGray};
`

const Svg = styled.svg`
  margin-right: 15px;
  width: 20px;
`

const Key = ({ status }) => (
  <Svg viewBox="0 0 10 10">
    {status === null && (
      <defs>
        <NullPattern />
      </defs>
    )}
    <KeyRect width="10" height="10" status={status} />
  </Svg>
)

export default Key
