import React from 'react'
import styled from 'styled-components'

import MultiSelect from '@kenshooui/react-multi-select'
import '@kenshooui/react-multi-select/dist/style.css'

import ExpandingSection from '../ExpandingSection/ExpandingSection'
// import RadioSelect from '../AdoptionOverTimePlot/PlotControls/RadioSelect'
import TypeaheadControl from '../TypeaheadControl/TypeaheadControl'
import TypeaheadResult from '../TypeaheadControl/TypeaheadResult'

const FilterBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 1rem;
`
const Filter = styled.div`
  margin-top: 0.5rem;
  width: 17rem;
  /* max-width: 80%; */
`
const ButtonLabel = styled.span`
  display: block;
  padding: 0.25em 1em;
  appearance: none;
  padding: 4px 8px;
  padding-right: 50px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 216 216' style='enable-background:new 0 0 216 216;' xml:space='preserve'%3E%3Crect style='fill:%23DDDDDD;' width='216' height='216'/%3E%3Cpolygon style='fill:%2358595B;' points='72,92.25 144,92.25 108,132.75 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center right;
  line-height: normal;
  border: 1px solid #aaa;
  border-radius: 5px;
  text-align: left;
`
const FilterFrame = styled.div`
  border: 1px solid #76767633;
  width: 400px;
  @media (max-width: 768px) {
    width: 350px;
  }
`
const Label = styled.label`
  margin-right: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  min-width: 20em;
`

const PlotControls = ({
  selectedRegions,
  setSelectedRegions,
  countries,
  highlightCountry,
  setHighlightCountry,
}) => {
  const [filterOpen, setFilterOpen] = React.useState(false)

  const onSelect = items => {
    if (items.length === 0)
      setSelectedRegions(prev => ({
        ...prev,
        selected: [{ id: 0, label: 'Global' }],
      }))
    else setSelectedRegions(prev => ({ ...prev, selected: items }))
  }

  return (
    <FilterBar>
      <Label>
        Choose global view or compare regions
        <Filter>
          <ExpandingSection
            floating
            open={filterOpen}
            onOpen={() => setFilterOpen(true)}
            onClose={() => setFilterOpen(false)}
          >
            <ButtonLabel>Select Regions</ButtonLabel>
            <FilterFrame>
              <MultiSelect
                showSelectedItems={false}
                items={selectedRegions.all}
                selectedItems={selectedRegions.selected}
                onChange={onSelect}
              />
            </FilterFrame>
          </ExpandingSection>
        </Filter>
      </Label>
      <Label>
        Find a country
        <TypeaheadControl
          placeholder={'Find a country'}
          items={countries}
          value={highlightCountry}
          onChange={country => setHighlightCountry(country)}
          renderItem={item => <TypeaheadResult>{item.label}</TypeaheadResult>}
        />
      </Label>
    </FilterBar>
  )
}

export default PlotControls
