import React from 'react'
import styled from 'styled-components'

import Key from './Key'

const Legend = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  padding-left: 0;
`
const Entry = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
`
const Label = styled.div`
  font-size: 16px;
  line-height: 1.3;
`

const DoughnutLegend = ({ adoption, countryCount, values }) => (
  <Legend>
    <Entry>
      <Key status={1} />
      <Label>
        Adopted ({Math.round((adoption[1] / countryCount) * 100)}%) <br />
        <strong>{adoption[1]} countries</strong>
      </Label>
    </Entry>
    {Object.keys(values).includes('Partially adopted') && (
      <Entry>
        <Key status={0.5} />
        <Label>
          Partially adopted ({Math.round((adoption[0.5] / countryCount) * 100)}
          %) <br />
          <strong>{adoption[0.5]} countries</strong>
        </Label>
      </Entry>
    )}
    <Entry>
      <Key status={0} />
      <Label>
        Not adopted ({Math.round((adoption[0] / countryCount) * 100)}%) <br />
        <strong>{adoption[0]} countries</strong>
      </Label>
    </Entry>
    <Entry>
      <Key status={null} />
      <Label>
        Data not available ({Math.round((adoption[null] / countryCount) * 100)}
        %) <br />
        <strong>{adoption[null]} countries</strong>
      </Label>
    </Entry>
  </Legend>
)

export default DoughnutLegend
