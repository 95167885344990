import React from 'react'
import styled from 'styled-components'

const AnimationGroup = styled.g`
  transition: 250ms ease;
`
const Spine = styled.path`
  stroke: ${props => props.theme.colorMiddleGray};
  stroke-width: 0.5px;
  transition: 250ms ease;
`
const Tick = styled.line`
  stroke: ${props => props.theme.colorMiddleGray};
  stroke-width: 0.5px;
  stroke-linecap: square;
`
const Label = styled.text`
  font-size: 7px;
  font-weight: 600;
  text-anchor: end;
  alignment-baseline: middle;
  fill: ${props => props.theme.colorBlack};
  transform: translateY(0.5px);
`

const AxisLabel = styled.text`
  font-size: 8px;
  text-anchor: middle;
  fill: ${props => props.theme.colorBlack};
`

const tickPad = 5
const xLabelPad = 40
const yLabelPad = 20

const Axes = ({ x, y, plotMax }) => (
  <>
    {/* <Spine x1={x.start} x2={x.end} y1={y.start} y2={y.start} /> */}
    <Spine d={`M ${x.start}, ${y.start} L ${x.end}, ${y.start}`} />
    <AnimationGroup transform={`translate(${x.start}, 0)`}>
      <Spine d={`M 0, ${y.start} L 0, ${y.end}`} />
      <Label x={-tickPad} y={y.start}>
        0
      </Label>
      <Tick x1={0} x2={-tickPad / 2} y1={y.start} y2={y.start} />
      <AnimationGroup transform={`translate(0, ${y.scale(plotMax / 2)})`}>
        <Label x={-tickPad}>{Math.round((plotMax / 2) * 100)}</Label>
        <Tick x1={0} x2={-tickPad / 2} y1={0} y2={0} />
      </AnimationGroup>
      <Label x={-tickPad} y={y.end}>
        {Math.round(plotMax * 100)}
      </Label>
      <Tick x1={0} x2={-tickPad / 2} y1={y.end} y2={y.end} />
      <AxisLabel x={-x.length / 2} y={y.start + xLabelPad}>
        Region
      </AxisLabel>
      <AxisLabel
        transform={`translate(${-yLabelPad}, ${
          y.start - y.length / 2
        }) rotate(-90)`}
      >
        % of countries
      </AxisLabel>
    </AnimationGroup>
  </>
)

export default Axes
