import React from 'react'
import styled from 'styled-components'

import lineStyles from './lineStyles'

const keyHeight = 14

const Label = styled.text`
  alignment-baseline: middle;
  transform: translateY(1px);
  font-size: 12px;
`

const TickMark = styled.path`
  fill: ${props => props.theme.colorBlack};
  transition: 150ms ease;
  opacity: ${props => (props.selected ? 1 : 0)};
`

const Legend = ({ partialAllowed, selectedCurves, setSelectedCurves }) => {
  const handleClick = key =>
    setSelectedCurves(prev => ({ ...prev, [key]: !prev[key] }))

  return (
    <g transform="translate(455, 10)">
      <g onClick={() => handleClick(1)} transform="translate(0,0)">
        <rect
          style={lineStyles[1]}
          y={0}
          width={keyHeight}
          height={keyHeight}
        />
        <Label x={keyHeight * 1.5} y={keyHeight / 2}>
          Adopted
        </Label>
        <TickMark
          transform={`translate(${1}, ${-3}) scale(.8)`}
          d="M2.89718 7.43204C2.23328 6.93654 1.29339 7.07306 0.797892 7.73696C0.302392 8.40087 0.438911 9.34075 1.10282 9.83625L2.89718 7.43204ZM8 18L6.55285 18.3947C6.72412 19.0226 7.28083 19.4685 7.93104 19.4984C8.58125 19.5283 9.17655 19.1355 9.40477 18.5259L8 18ZM20.851 3.23523C21.5332 2.76523 21.7052 1.83119 21.2352 1.14899C20.7652 0.466795 19.8312 0.294774 19.149 0.764773L20.851 3.23523ZM1.10282 9.83625C3.32058 11.4915 5.40897 14.2004 6.55285 18.3947L9.44715 17.6053C8.13648 12.7996 5.67942 9.50854 2.89718 7.43204L1.10282 9.83625ZM9.40477 18.5259C11.396 13.2073 13.8419 8.06415 20.851 3.23523L19.149 0.764773C11.4308 6.08219 8.6949 11.8658 6.59523 17.4741L9.40477 18.5259Z"
          selected={selectedCurves[1]}
        />
      </g>
      <g onClick={() => handleClick(0)} transform="translate(0,0)">
        <rect
          style={lineStyles[0]}
          y={keyHeight * 1.75}
          width={keyHeight}
          height={keyHeight}
        />
        <Label x={keyHeight * 1.5} y={keyHeight * 2.25}>
          Not Adopted
        </Label>
        <TickMark
          transform={`translate(${1}, ${keyHeight * 1.75 - 3}) scale(.8)`}
          d="M2.89718 7.43204C2.23328 6.93654 1.29339 7.07306 0.797892 7.73696C0.302392 8.40087 0.438911 9.34075 1.10282 9.83625L2.89718 7.43204ZM8 18L6.55285 18.3947C6.72412 19.0226 7.28083 19.4685 7.93104 19.4984C8.58125 19.5283 9.17655 19.1355 9.40477 18.5259L8 18ZM20.851 3.23523C21.5332 2.76523 21.7052 1.83119 21.2352 1.14899C20.7652 0.466795 19.8312 0.294774 19.149 0.764773L20.851 3.23523ZM1.10282 9.83625C3.32058 11.4915 5.40897 14.2004 6.55285 18.3947L9.44715 17.6053C8.13648 12.7996 5.67942 9.50854 2.89718 7.43204L1.10282 9.83625ZM9.40477 18.5259C11.396 13.2073 13.8419 8.06415 20.851 3.23523L19.149 0.764773C11.4308 6.08219 8.6949 11.8658 6.59523 17.4741L9.40477 18.5259Z"
          selected={selectedCurves[0]}
        />
      </g>
      {partialAllowed && (
        <g onClick={() => handleClick(0.5)} transform="translate(0,0)">
          <rect
            style={lineStyles[0.5]}
            y={keyHeight * 3.5}
            width={keyHeight}
            height={keyHeight}
          />
          <Label x={keyHeight * 1.5} y={keyHeight * 4}>
            Partial
          </Label>
          <TickMark
            transform={`translate(${1}, ${keyHeight * 3.5 - 3}) scale(.8)`}
            d="M2.89718 7.43204C2.23328 6.93654 1.29339 7.07306 0.797892 7.73696C0.302392 8.40087 0.438911 9.34075 1.10282 9.83625L2.89718 7.43204ZM8 18L6.55285 18.3947C6.72412 19.0226 7.28083 19.4685 7.93104 19.4984C8.58125 19.5283 9.17655 19.1355 9.40477 18.5259L8 18ZM20.851 3.23523C21.5332 2.76523 21.7052 1.83119 21.2352 1.14899C20.7652 0.466795 19.8312 0.294774 19.149 0.764773L20.851 3.23523ZM1.10282 9.83625C3.32058 11.4915 5.40897 14.2004 6.55285 18.3947L9.44715 17.6053C8.13648 12.7996 5.67942 9.50854 2.89718 7.43204L1.10282 9.83625ZM9.40477 18.5259C11.396 13.2073 13.8419 8.06415 20.851 3.23523L19.149 0.764773C11.4308 6.08219 8.6949 11.8658 6.59523 17.4741L9.40477 18.5259Z"
            selected={selectedCurves[0.5]}
          />
        </g>
      )}
    </g>
  )
}

export default Legend
