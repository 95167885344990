import React from 'react'
import axios from 'axios'
import styled from 'styled-components'

import * as d3 from 'd3'
import { feature } from 'topojson'
import NullPattern from '../NullPattern'

const Path = styled.path`
  stroke: white;
  stroke-width: 0.5;
  transition: 250ms ease;
`

const SVGMap = ({ colors, data }) => {
  const scale = 0.75
  const [width, height] = [500 * scale, 275 * scale]

  const [paths, setPaths] = React.useState()

  React.useEffect(() => {
    const getWorldTopology = async () => {
      axios.get('/maps/world-simplified.json').then(response => {
        const features = feature(
          response.data,
          response.data.objects.world
        ).features.filter(country => country.properties.ISO3 !== 'ATA')

        const projection = d3.geoNaturalEarth1().fitSize([width, height], {
          type: 'FeatureCollection',
          features,
        })

        const countryPaths = features.map(geo => (
          <Path
            key={geo.properties.ISO2}
            fill={
              colors[data[geo.properties.ISO2]?.adoption] || 'url(#nullPattern)'
            }
            d={d3.geoPath().projection(projection)(geo)}
          />
        ))

        setPaths(countryPaths)
      })
    }
    getWorldTopology()
  }, [])

  return (
    <svg viewBox={`0 0 ${width} ${height}`}>
      <defs>
        <NullPattern />
      </defs>
      {paths}
    </svg>
  )
}

export default SVGMap
