import React from 'react'
import styled from 'styled-components'

// policy layout just extends Layout.js with the figma stuff basically
import PolicyLayout from '../components/policypage/PolicyLayout/PolicyLayout'

import PolicyAbbr from '../components/policypage/PolicyAbbr'
import Section from '../components/policypage/Section'
import DetailTable from '../components/policypage/DetailTable/DetailTable'
import IntroSection from '../components/policypage/IntroSection/IntroSection'
import RegionalAdoptionPlot from '../components/policypage/RegionalAdoptionPlot/RegionalAdoptionPlot'
import AdoptionOverTimePlot from '../components/policypage/AdoptionOverTimePlot/AdoptionOverTimePlot'
import AdoptionTableSection from '../components/policypage/AdoptionTable/AdoptionTableSection'

const Main = styled.main`
  margin: auto;
  padding: 20px;
  max-width: 1440px;
`
const HiddenSM = styled(Section)`
  @media only screen and (max-width: 1000px) {
    display: none;
  }
`
const CodebookHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  p {
    margin-bottom: 10px;
  }
`

const PolicyTemplate = props => (
  <PolicyLayout>
    <Main>
      <header>
        <h4>{props.pageContext.category}</h4>
        <h1>
          <PolicyAbbr>{props.pageContext.abbr}</PolicyAbbr>
          {props.pageContext.name}
        </h1>
      </header>
      <IntroSection {...props} />
      <Section skipHeader {...props}>
        <CodebookHeader>
          <h2 style={{ textAlign: 'left' }}>Codebook</h2>
          <p>
            <a href="/sources" target="_blank">
              Learn more from our partners
            </a>
          </p>
        </CodebookHeader>
        <DetailTable collapsible {...props} />
      </Section>
      <Section skipHeader {...props}>
        <RegionalAdoptionPlot {...props} />
      </Section>
      <Section {...props}>
        <AdoptionOverTimePlot {...props} />
      </Section>
      <HiddenSM {...props}>
        <h2>Adoption status by country</h2>
        <AdoptionTableSection {...props} />
      </HiddenSM>
    </Main>
  </PolicyLayout>
)

export default PolicyTemplate
