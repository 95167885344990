import React from 'react'
import styled from 'styled-components'
import Flag from '../Flag'

import Tooltip from './Tooltip'

const Label = styled.text`
  font-size: 7px;
  font-weight: 600;
  text-anchor: middle;
  fill: ${props => props.theme.colorBlack};
`

const Path = styled.path`
  transition: 250ms ease;
  cursor: pointer;
  fill: ${props =>
    ({
      null: 'url(#nullPattern)',
      0: props.theme.colorVeryFew,
      0.5: props.theme.colorSome,
      1: props.theme.colorMost,
    }[props.status])};
  stroke-width: 0.25;
  stroke: ${props => props.theme.colorMiddleGray};
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: 0.25px solid blue;
  }
`

const FlagHolder = styled.foreignObject`
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const labelPad = 10

const YearBar = ({ year, stack, index, dim, highlightCountry }) => (
  <g
    style={{
      transform: `translateX(${dim.axes.x.scale(index) - dim.barWidth / 2}px)`,
    }}
  >
    <Label x={dim.barWidth / 2} y={dim.axes.y.start + labelPad}>
      {year}
    </Label>
    {Object.entries(stack).map(([status, bar]) => (
      <Tooltip key={status} {...{ year, bar, status }}>
        <Path
          status={status}
          d={`
            M 0,${dim.axes.y.scale(bar.start)}
            L 0,${dim.axes.y.scale(bar.end)}
            L ${dim.barWidth},${dim.axes.y.scale(bar.end)}
            L ${dim.barWidth},${dim.axes.y.scale(bar.start)}
          `.replace(/\s+/g, '')}
        />
      </Tooltip>
    ))}
    {Object.values(stack).map(
      bar =>
        highlightCountry &&
        bar.countries.map(c => c.place_id).includes(highlightCountry.key) && (
          <FlagHolder
            key={highlightCountry.key}
            y={dim.axes.y.scale(bar.end + (bar.start - bar.end) / 2) - 9}
            style={{ pointerEvents: 'none', transition: '250ms ease' }}
            width={dim.barWidth}
          >
            <Flag
              style={{ height: 20, margin: 'auto' }}
              iso2={highlightCountry.key}
            />
          </FlagHolder>
        )
    )}
  </g>
)

export default YearBar
