import React from 'react'
import styled from 'styled-components'

import RadioSelect from './RadioSelect'
import TypeaheadControl from '../../TypeaheadControl/TypeaheadControl'
import TypeaheadResult from '../../TypeaheadControl/TypeaheadResult'

const FilterBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 1rem;
`
const Label = styled.label`
  margin-right: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  min-width: 20em;
`

const PlotControls = ({
  selectedRegions,
  setSelectedRegions,
  countries,
  highlightCountry,
  setHighlightCountry,
}) => {
  return (
    <FilterBar>
      <Label>
        Choose global or regional view
        <RadioSelect
          state={selectedRegions}
          stateSetter={setSelectedRegions}
          defaultLabel={'Select Region'}
          defaultSelection={[{ id: 0, label: 'Global' }]}
        />
      </Label>
      <Label>
        Find a country
        <TypeaheadControl
          placeholder={'Find a country'}
          items={countries}
          value={highlightCountry}
          onChange={country => setHighlightCountry(country)}
          renderItem={country => (
            <TypeaheadResult>{country.label}</TypeaheadResult>
          )}
        />
      </Label>
    </FilterBar>
  )
}

export default PlotControls
