import React from 'react'
import styled from 'styled-components'

const axisFontSize = 7

const Spine = styled.line`
  stroke: ${props => props.theme.colorMiddleGray};
  stroke-width: 0.5px;
`
const Tick = styled.line`
  stroke: ${props => props.theme.colorMiddleGray};
  stroke-width: 0.5px;
  stroke-linecap: square;
`
const Label = styled.text`
  font-size: 7px;
  font-weight: 600;
  text-anchor: end;
  alignment-baseline: middle;
  fill: ${props => props.theme.colorBlack};
  transform: translateY(0.5px);
`

const AxisLabel = styled.text`
  font-size: ${axisFontSize}px;
  text-anchor: middle;
  fill: ${props => props.theme.colorBlack};
`

const tickPad = 5
const xLabelPad = 25
const yLabelPad = 34

const Axes = ({ x, y, region }) => (
  <>
    <Spine x1={x.start} x2={x.end} y1={y.start} y2={y.start} />
    <Spine x1={x.start} x2={x.start} y1={y.start} y2={y.end} />
    <Label x={x.start - tickPad} y={y.start}>
      0
    </Label>
    <Tick x1={x.start} x2={x.start - tickPad / 2} y1={y.start} y2={y.start} />
    <Label x={x.start - tickPad} y={y.scale(0.5)}>
      50
    </Label>
    <Tick
      x1={x.start}
      x2={x.start - tickPad / 2}
      y1={y.scale(0.5)}
      y2={y.scale(0.5)}
    />
    <Label x={x.start - tickPad} y={y.end}>
      100
    </Label>
    <Tick x1={x.start} x2={x.start - tickPad / 2} y1={y.end} y2={y.end} />
    <AxisLabel x={x.start + x.length / 2} y={y.start + xLabelPad}>
      Current adoption status each year
    </AxisLabel>
    <AxisLabel
      transform={`translate(${x.start - yLabelPad}, ${
        y.start - y.length / 2
      }) rotate(-90)`}
    >
      <tspan>% of countries</tspan>
      <tspan x={0} dy={axisFontSize * 1.4}>
        {region}
      </tspan>
    </AxisLabel>
  </>
)

export default Axes
